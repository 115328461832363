import {
    type GmDtoLibraryDtoTransportAssignmentTransportOrderDto,
    type GmDtoLibraryDtoTransportAssignmentTransportShipmentDto,
    TransportAssignmentApi
} from '~/gen/openapi/sblService';
import {useAxiosClient} from '~/use/axios/client';
import {notNil} from '~/helpers/notNil';
import {extractErrorMessagesFromErrorOrResponse} from '~/helpers/errors';

const state = reactive({
    parkedTransportOrders: [] as GmDtoLibraryDtoTransportAssignmentTransportOrderDto[],
    parkedTransportFetchError: '',
    isLoadingParkedOrders: false,
    parkedTransportShipmentDetailsByVsysId: {} as Record<string, GmDtoLibraryDtoTransportAssignmentTransportShipmentDto[]>,
    isLoadingDetails: false,
})
const { axiosClient } = useAxiosClient()

const parkedTransportOrders = computed(() => state.parkedTransportOrders)
const parkedTransportFetchError = computed(() => state.parkedTransportFetchError)
const isLoadingParkedOrders = computed(() => state.isLoadingParkedOrders)
const isLoadingDetails = computed(() => state.isLoadingDetails)
const parkedTransportShipmentDetailsByVsysId = computed(() => state.parkedTransportShipmentDetailsByVsysId)

const loadParkedTransportOrders = async () => {
    const transportApi = new TransportAssignmentApi(undefined, '', axiosClient.value)

    transportApi.transportAssignmentGetParkedOrdersList().then((response) => {
        state.parkedTransportOrders = response.data.FollowUpListModel || []
    }).then(() => {
        loadDetails()
    }).catch((error) => {
        state.parkedTransportFetchError = extractErrorMessagesFromErrorOrResponse(error)[0]
    }).finally(() => {
        state.isLoadingParkedOrders = false
    })
}

const loadDetails = async () => {
    state.isLoadingDetails = true
    const transportApi = new TransportAssignmentApi(undefined, '', axiosClient.value)

    const contractVsysIds = state.parkedTransportOrders.map((order) => order.HeadId).filter(notNil)
    const promises = []
    for (const contractVsysId of contractVsysIds) {
        promises.push(transportApi.transportAssignmentGetReadyToReactivateShipmentList(contractVsysId+'').then((response) => {
            state.parkedTransportShipmentDetailsByVsysId[contractVsysId] = response.data || []
        }))
    }
    await Promise.all(promises)
        .catch((error) => {
            state.parkedTransportFetchError = extractErrorMessagesFromErrorOrResponse(error)[0]
        })
        .finally(() => {
            state.isLoadingDetails = false
        })
}

const reactivateShipment = async (shipmentId: string) => {
    const transportApi = new TransportAssignmentApi(undefined, '', axiosClient.value)

    const result = await transportApi.transportAssignmentReactivateShipmentAction(shipmentId)

    state.parkedTransportShipmentDetailsByVsysId = Object.fromEntries(
        Object.entries(state.parkedTransportShipmentDetailsByVsysId).map(([vsysId, shipments]) => {
                return [vsysId, shipments.filter((shipment) => shipment.ShipmentEventId !== shipmentId)]
            })
    )

    return result
}

const reactivateOrder = async (orderId: string) => {
    const transportApi = new TransportAssignmentApi(undefined, '', axiosClient.value)

    const result = await transportApi.transportAssignmentReactivateOrderAction(orderId)
    state.parkedTransportOrders = parkedTransportOrders.value.filter((order) => order.HeadId !== orderId)

    return result
}

export const useTransportStore = () => ({
    parkedTransportOrders,
    parkedTransportFetchError,
    isLoadingParkedOrders,
    loadParkedTransportOrders,
    parkedTransportShipmentDetailsByVsysId,
    isLoadingDetails,
    reactivateShipment,
    reactivateOrder,
})
