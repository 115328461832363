/* tslint:disable */
/* eslint-disable */
/**
 * Mjøsen Skog skogbruksleder API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { GmDtoLibraryDtoTransportAssignmentTransportOrdersListDto } from '../model';
// @ts-ignore
import { GmDtoLibraryDtoTransportAssignmentTransportShipmentDto } from '../model';
/**
 * TransportAssignmentApi - axios parameter creator
 * @export
 */
export const TransportAssignmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get list of orders that need followup???  - action \"ctg_ParkedTransportOrder_GetFollowUpPurchaseGridList\"
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transportAssignmentGetParkedOrdersList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/transport/get-parked-orders-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of orders that can be reactivated  - action \"ctg_ParkedTransportOrder_GetVolumeDeliveredToRoadGroup\"
         * @param {string} [contractVsysId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transportAssignmentGetReadyToReactivateShipmentList: async (contractVsysId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/transport/get-ready-to-reactivate-shipment-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contractVsysId !== undefined) {
                localVarQueryParameter['contractVsysId'] = contractVsysId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reactivate all orders on contract  - action \"ctg_ParkedTransportOrder_PostReactivateAllTransportOrders\"
         * @param {string} [contractVsysId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transportAssignmentReactivateOrderAction: async (contractVsysId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/transport/reactivate-all-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contractVsysId !== undefined) {
                localVarQueryParameter['contractVsysId'] = contractVsysId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reactivate one order  - action \"ctg_ParkedTransportOrder_PostReactivateTransportOrder\"
         * @param {string} [shipmentEventId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transportAssignmentReactivateShipmentAction: async (shipmentEventId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/transport/reactivate-shipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (shipmentEventId !== undefined) {
                localVarQueryParameter['shipmentEventId'] = shipmentEventId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransportAssignmentApi - functional programming interface
 * @export
 */
export const TransportAssignmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransportAssignmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get list of orders that need followup???  - action \"ctg_ParkedTransportOrder_GetFollowUpPurchaseGridList\"
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transportAssignmentGetParkedOrdersList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmDtoLibraryDtoTransportAssignmentTransportOrdersListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transportAssignmentGetParkedOrdersList(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TransportAssignmentApi.transportAssignmentGetParkedOrdersList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get list of orders that can be reactivated  - action \"ctg_ParkedTransportOrder_GetVolumeDeliveredToRoadGroup\"
         * @param {string} [contractVsysId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transportAssignmentGetReadyToReactivateShipmentList(contractVsysId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmDtoLibraryDtoTransportAssignmentTransportShipmentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transportAssignmentGetReadyToReactivateShipmentList(contractVsysId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TransportAssignmentApi.transportAssignmentGetReadyToReactivateShipmentList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Reactivate all orders on contract  - action \"ctg_ParkedTransportOrder_PostReactivateAllTransportOrders\"
         * @param {string} [contractVsysId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transportAssignmentReactivateOrderAction(contractVsysId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transportAssignmentReactivateOrderAction(contractVsysId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TransportAssignmentApi.transportAssignmentReactivateOrderAction']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Reactivate one order  - action \"ctg_ParkedTransportOrder_PostReactivateTransportOrder\"
         * @param {string} [shipmentEventId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transportAssignmentReactivateShipmentAction(shipmentEventId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transportAssignmentReactivateShipmentAction(shipmentEventId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TransportAssignmentApi.transportAssignmentReactivateShipmentAction']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TransportAssignmentApi - factory interface
 * @export
 */
export const TransportAssignmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransportAssignmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Get list of orders that need followup???  - action \"ctg_ParkedTransportOrder_GetFollowUpPurchaseGridList\"
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transportAssignmentGetParkedOrdersList(options?: any): AxiosPromise<GmDtoLibraryDtoTransportAssignmentTransportOrdersListDto> {
            return localVarFp.transportAssignmentGetParkedOrdersList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of orders that can be reactivated  - action \"ctg_ParkedTransportOrder_GetVolumeDeliveredToRoadGroup\"
         * @param {string} [contractVsysId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transportAssignmentGetReadyToReactivateShipmentList(contractVsysId?: string, options?: any): AxiosPromise<Array<GmDtoLibraryDtoTransportAssignmentTransportShipmentDto>> {
            return localVarFp.transportAssignmentGetReadyToReactivateShipmentList(contractVsysId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reactivate all orders on contract  - action \"ctg_ParkedTransportOrder_PostReactivateAllTransportOrders\"
         * @param {string} [contractVsysId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transportAssignmentReactivateOrderAction(contractVsysId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.transportAssignmentReactivateOrderAction(contractVsysId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reactivate one order  - action \"ctg_ParkedTransportOrder_PostReactivateTransportOrder\"
         * @param {string} [shipmentEventId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transportAssignmentReactivateShipmentAction(shipmentEventId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.transportAssignmentReactivateShipmentAction(shipmentEventId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransportAssignmentApi - object-oriented interface
 * @export
 * @class TransportAssignmentApi
 * @extends {BaseAPI}
 */
export class TransportAssignmentApi extends BaseAPI {
    /**
     * 
     * @summary Get list of orders that need followup???  - action \"ctg_ParkedTransportOrder_GetFollowUpPurchaseGridList\"
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransportAssignmentApi
     */
    public transportAssignmentGetParkedOrdersList(options?: RawAxiosRequestConfig) {
        return TransportAssignmentApiFp(this.configuration).transportAssignmentGetParkedOrdersList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of orders that can be reactivated  - action \"ctg_ParkedTransportOrder_GetVolumeDeliveredToRoadGroup\"
     * @param {string} [contractVsysId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransportAssignmentApi
     */
    public transportAssignmentGetReadyToReactivateShipmentList(contractVsysId?: string, options?: RawAxiosRequestConfig) {
        return TransportAssignmentApiFp(this.configuration).transportAssignmentGetReadyToReactivateShipmentList(contractVsysId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reactivate all orders on contract  - action \"ctg_ParkedTransportOrder_PostReactivateAllTransportOrders\"
     * @param {string} [contractVsysId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransportAssignmentApi
     */
    public transportAssignmentReactivateOrderAction(contractVsysId?: string, options?: RawAxiosRequestConfig) {
        return TransportAssignmentApiFp(this.configuration).transportAssignmentReactivateOrderAction(contractVsysId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reactivate one order  - action \"ctg_ParkedTransportOrder_PostReactivateTransportOrder\"
     * @param {string} [shipmentEventId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransportAssignmentApi
     */
    public transportAssignmentReactivateShipmentAction(shipmentEventId?: string, options?: RawAxiosRequestConfig) {
        return TransportAssignmentApiFp(this.configuration).transportAssignmentReactivateShipmentAction(shipmentEventId, options).then((request) => request(this.axios, this.basePath));
    }
}

